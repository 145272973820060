/* eslint-disable indent */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable global-require */
/* eslint-disable no-useless-concat */
/* eslint-disable no-useless-escape */
// import axios from 'axios'
import { paginateArray, sortCompare } from '@core/utils/filter'
import instance from '@/libs/axios.instance'
import authHeader from '@/libs/auth.header'

class LocalTransferService {

	getLocalTransferLists(queryParams) {
		const pars = {
			username: queryParams.username,
			userId: queryParams.userId,
			email: queryParams.email,
			name: queryParams.fullname,
			phone: queryParams.phone,
			reference: queryParams.reference,
			fromDate: queryParams.fromdate,
			toDate: queryParams.todate,
			status: queryParams.stat,
			localTransferTypeId: queryParams.localTransferTypeId,
			pageNumber: queryParams.page,
			pageSize: queryParams.perPage
		}
		return instance.get('/api/local-transfer/index', { headers: authHeader(), params: pars })
				.then(
					response => {
						const datas = {
							localTransfers: response.data.data,
							total: response.data.count,
							pageSize: queryParams.page == 1 ? response.data.data.length : 25
						}
						return datas
					}
				)
	}

    getLocalTransferType() {
		return instance.get('/api/local-transfer-type/index', { headers: authHeader() })
                .then(response => {
                    return response.data
                })
	}

	updateLocalTransferStatus(status) {
		const FormData = require('form-data')
		const data = new FormData()
		data.append('localTransferId', status.id)
		data.append('status', status.status)
		data.append('reject_reason', status.reason)

		return instance.post('/api/local-transfer/update-status', data, { headers: authHeader() })
	}
}

export default new LocalTransferService()
