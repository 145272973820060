import LocalBankAccountService from '@/libs/local-bank-account.service'

export default {
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		fetchLocalBankAccounts(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				LocalBankAccountService.getLocalBankAccountLists(queryParams)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		addLocalBankAccount(ctx, params) {
			return new Promise((resolve, reject) => {
				LocalBankAccountService.addLocalBankAccount(params)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		updateLocalBankAccountStatus(ctx, params) {
			return new Promise((resolve, reject) => {
				LocalBankAccountService.updateStatus(params)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
    }
}
