/* eslint-disable indent */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable global-require */
/* eslint-disable no-useless-concat */
/* eslint-disable no-useless-escape */
// import axios from 'axios'
import instance from '@/libs/axios.instance'
import authHeader from '@/libs/auth.header'

class LocalBankAccountService {

	getLocalBankAccountLists(queryParams) {
		const pars = {
			pageNumber: queryParams.page,
			pageSize: queryParams.perPage
		}
		return instance.get('/api/local-bank-account/index', { headers: authHeader(), params: pars })
				.then(
					response => {
						const datas = {
							localBankAccounts: response.data.data,
							total: response.data.count,
							pageSize: queryParams.page == 1 ? response.data?.data?.length : 25
						}
						return datas
					}
				)
	}

	addLocalBankAccount(localBankAccountData) {
		const FormData = require('form-data')
		const data = new FormData()
		data.append('bankAccountName', localBankAccountData.bankAccountName)
		data.append('bankAccount', localBankAccountData.bankAccount)
		data.append('bankId', localBankAccountData.bankId)
		
		return instance.post('/api/local-bank-account/create', data, { headers: authHeader() })
	}

	updateStatus(localBankAccountData) {
		const FormData = require('form-data')
		const data = new FormData()
		data.append('local_bank_account_id', localBankAccountData.local_bank_account_id);
		data.append('bankAccountName', localBankAccountData.bankAccountName)
		data.append('bankAccount', localBankAccountData.bankAccount)
		data.append('bankId', localBankAccountData.bankId)
		data.append('status', localBankAccountData.status)
		return instance.post('/api/local-bank-account/update', data, { headers: authHeader() })
	}
}

export default new LocalBankAccountService()
