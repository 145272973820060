import LocalTransferService from '@/libs/local-transfer.service'

export default {
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		fetchLocalTransfers(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				LocalTransferService.getLocalTransferLists(queryParams)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		fetchLocalTransferType(ctx) {
			return new Promise((resolve, reject) => {
				LocalTransferService.getLocalTransferType()
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		updateLocalTransferStatus(ctx, localTransferData) {
			return new Promise((resolve, reject) => {
				LocalTransferService.updateLocalTransferStatus(localTransferData)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
	}
}
