/* eslint-disable vue/html-self-closing */
<template>

	<div>
		<local-bank-account-list-add-new
			v-if="bankOptions && provinceLists && levelLists"
			:type-options="typeOptions"
			:bank-options="bankOptions"
			:province-lists="provinceLists"
			:level-lists="levelLists"
			:local-transfer-type-lists="localTransferTypeLists"
			@refetch-data="refetchData"
		/>

		<b-card
			no-body
			class="mb-0"
		>
			<div
				v-if="$can('create','localbankaccount')"
				class="m-2"
			>
				<b-row>
					<b-col
						cols="12"
					>
						<div class="d-flex align-items-center justify-content-end">
							<b-button
								variant="primary"
								@click="showModalAddBatchAdjustment()"
							>
								<span class="text-nowrap">
									<feather-icon
										icon="PlusCircleIcon"
									/>
									{{ $t('Create') }}
								</span>
							</b-button>
						</div>
					</b-col>
				</b-row>

			</div>

			<b-table
				ref="refLocalBankAccountListTable"
				class="position-relative table-white-space max-height-table"
				sticky-header
				head-variant="light"
				:no-border-collapse="true"
				:items="fetchLocalBankAccounts"
				responsive
				:fields="tableColumns"
				primary-key="id"
				:sort-by.sync="sortBy"
				show-empty
				empty-text="No matching records found"
				:sort-desc.sync="isSortDirDesc"
			>
				<template #cell(index)="data">
					{{ perPage * (currentPage - 1) + data.index + 1 }}
				</template>

				<template #cell(bank)="data">
					{{ data.item.bank.bank_name}}
				</template>

				<!-- Column: Name -->
				<template #cell(vbank)="data">
					{{ resolveVBank(data.item.account, data.item.bank.code) }}
				</template>

				<template #cell(status)="data">
					<div
						:class="`text-capitalize align-items-center d-flex cursor-pointer`"
						@click="showModalUpdateStatus(data.item)"
					>
						<span :class="`dot bg-${resolveStatus(data.item.stat).variant}`" />{{ resolveStatus(data.item.stat).label }}
					</div>
				</template>

				<template #cell(createdBy)="data">
					{{ data.item.created_by }}
				</template>

				<template #cell(createdAt)="data">
					{{ (data.item.created_at) ? formatDateTime(data.item.created_at).replace('.000000Z', '') : '-' }}
				</template>

				<template #cell(updatedAt)="data">
					{{ (data.item.updated_at) ? formatDateTime(data.item.updated_at).replace('.000000Z', '') : '-' }}
				</template>
        <template #cell(action)="data">
          <b-link
              v-b-tooltip.hover.v-info
			  v-if="$can('update', 'localbankaccountstatus')"
              :title="$t('Edit')"
              @click="showModalUpdateStatus(data.item)"	
          >
            <feather-icon icon="Edit3Icon" size="16"/>
          </b-link>
        </template>
			</b-table>

			<div class="mx-2 mb-2">
				<b-row>

					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start"
					>
						<span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
					</b-col>
					<!-- Pagination -->
					<b-col
						cols="5"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end"
					>

						<b-pagination
							v-model="currentPage"
							:total-rows="totalLocalBankAccounts"
							:per-page="perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item"
						>
							<template #prev-text>
								<feather-icon
									icon="ChevronLeftIcon"
									size="18"
								/>
							</template>
							<template #next-text>
								<feather-icon
									icon="ChevronRightIcon"
									size="18"
								/>
							</template>
						</b-pagination>

					</b-col>

				</b-row>
			</div>
		</b-card>

		<local-bank-account-change-status-modal
			v-if="bankOptions"
			:local-bank-account.sync="localBankAccount"
			:status-options="statusOptions"
			:bank-options="bankOptions"
			@refetch-data="refetchData"
		/>
	</div>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, onUnmounted } from '@vue/composition-api'
import { formatDateTime, resolveVBank } from '@core/utils/filter'
import store from '@/store'
import useLocalBankAccountsList from './useLocalBankAccountsList'
import localBankAccountStoreModule from './localBankAccountStoreModule'
import LocalBankAccountListAddNew from './LocalBankAccountListAddNew.vue'
import LocalBankAccountChangeStatusModal from './LocalBankAccountChangeStatusModal.vue'
import bankStoreModule from '@/views/local-setting/local/bankStoreModule'
import userStoreModule from '@/views/apps/user/userStoreModule'
import localTransferStoreModule from '@/views/payments/local-transfer/localTransferStoreModule'

export default {
	components: {
		LocalBankAccountListAddNew,
		LocalBankAccountChangeStatusModal,
		BCard,
		BRow,
		BCol,
		BFormInput,
		BButton,
		BTable,
		BMedia,
		BAvatar,
		BLink,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,
		vSelect,
	},
  directives: {
    'b-tooltip':VBTooltip,
  },
	data () {
		return {
			localBankAccount: null,
		}
	},
	methods: {
		showModalUpdateStatus(data) {
			this.localBankAccount = data
			this.$bvModal.show('modal-update-status-local-bank-account')
		},
		showModalAddBatchAdjustment() {
			this.$bvModal.show("add-new-local-bank-account-sidebar");
		},
	},
	setup() {

		const isAddNewLocalBankAccountSidebarActive = ref(false)
		const provinceLists = ref(null)
		const levelLists = ref(null)
		const localTransferTypeLists = ref([])

		const PAYMENT_LOCAL_BANK_ACCOUNT_STORE_MODULE_NAME = 'payment-local-bank-account'
		const LOCAL_SETTING_BANK_STORE_MODULE_NAME = 'local-setting-bank'
		const USER_APP_STORE_MODULE_NAME = 'app-user'
		const PAYMENT_LOCAL_TRANSFER_STORE_MODULE_NAME = 'payment-local-transfer'

		// Register module
		if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
		if (!store.hasModule(PAYMENT_LOCAL_BANK_ACCOUNT_STORE_MODULE_NAME)) store.registerModule(PAYMENT_LOCAL_BANK_ACCOUNT_STORE_MODULE_NAME, localBankAccountStoreModule)
		if (!store.hasModule(LOCAL_SETTING_BANK_STORE_MODULE_NAME)) store.registerModule(LOCAL_SETTING_BANK_STORE_MODULE_NAME, bankStoreModule)
		if (!store.hasModule(PAYMENT_LOCAL_TRANSFER_STORE_MODULE_NAME)) store.registerModule(PAYMENT_LOCAL_TRANSFER_STORE_MODULE_NAME, localTransferStoreModule)
		// UnRegister on leave
		onUnmounted(() => {
			if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
			if (store.hasModule(PAYMENT_LOCAL_BANK_ACCOUNT_STORE_MODULE_NAME)) store.unregisterModule(PAYMENT_LOCAL_BANK_ACCOUNT_STORE_MODULE_NAME)
			if (store.hasModule(LOCAL_SETTING_BANK_STORE_MODULE_NAME)) store.unregisterModule(LOCAL_SETTING_BANK_STORE_MODULE_NAME)
			if (store.hasModule(PAYMENT_LOCAL_TRANSFER_STORE_MODULE_NAME)) store.unregisterModule(PAYMENT_LOCAL_TRANSFER_STORE_MODULE_NAME)
		})

		const typeOptions = [
			{ label: 'Cộng Tiền', value: '1' },
			{ label: 'Trừ Tiền', value: '2' },
		]

		store.dispatch('app-user/fetchProvinces')
			.then(response => {
				provinceLists.value = response.data.data
			})

		store.dispatch('app-user/fetchLevel')
			.then(response => {
				levelLists.value = response.data.data
			})

		/* store.dispatch('payment-local-transfer/fetchLocalTransferType')
			.then(response => {
				localTransferTypeLists.value = response.data
			}) */

		const {
			fetchLocalBankAccounts,
			bankOptions,
			tableColumns,
			perPage,
			currentPage,
			totalLocalBankAccounts,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refLocalBankAccountListTable,
			refetchData,

			// UI
			resolveStatus,
			statusOptions,

			// Extra Filters
			statusFilter,
		} = useLocalBankAccountsList()

		return {
			isAddNewLocalBankAccountSidebarActive,

			fetchLocalBankAccounts,
			bankOptions,
			tableColumns,
			perPage,
			currentPage,
			totalLocalBankAccounts,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refLocalBankAccountListTable,
			refetchData,

			// Filter
			formatDateTime,
			resolveVBank,

			// UI
			resolveStatus,

			typeOptions,
			statusOptions,

			// Extra Filters
			statusFilter,

			provinceLists,
			levelLists,
			localTransferTypeLists,
		}
	},
}
</script>