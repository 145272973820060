<template>
	<b-modal
		id="add-new-local-bank-account-sidebar"
		cancel-variant="outline-secondary"
		ok-title="Add"
		cancel-title="Close"
		ok-variant="primary"
		centered
		size="md"
		:title="$t('Add new local bank account')" 
		@ok="onSubmit()"
		@hidden="resetlocalBankData()"
		:no-close-on-backdrop="true"
	>

			<!-- BODY -->
		<validation-observer
			ref="refFormObserver"
		>
			<!-- Form -->
			<b-form
				class="p-2"
				@submit.prevent="onSubmit()"
				method="POST"
			>
				<!-- Bank Account Name -->
				<validation-provider
					#default="validationContext"
					name="bankAccountName"
					rules="required|alpha_spaces|min:3|max:30"
				>
					<b-form-group
						:label="$t('Bank account name')"
						label-for="bank-account-name"
					>
						<b-form-input
							v-model="localBankData.bankAccountName"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:state="getValidationState(validationContext)"
							input-id="bank-account-name"
						/>
						<b-form-invalid-feedback>
							{{ validationContext.errors[0] }}
						</b-form-invalid-feedback>
					</b-form-group>
				</validation-provider>

				<!-- Bank Account -->
				<validation-provider
					#default="validationContext"
					name="Bank Account"
					rules="required|numeric|min:6|max:20"
				>
					<b-form-group
						:label="$t('Bank account')"
						label-for="bank-account"
					>
						<b-form-input
							v-model="localBankData.bankAccount"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:state="getValidationState(validationContext)"
							input-id="bank-account"
						/>
						<b-form-invalid-feedback>
							{{ validationContext.errors[0] }}
						</b-form-invalid-feedback>
					</b-form-group>
				</validation-provider>

				<!-- Bank -->
				<validation-provider
					#default="validationContext"
					name="bank"
					rules="required"
				>
					<b-form-group
						:label="$t('Bank')"
						label-for="bank"
						:state="getValidationState(validationContext)"
					>
						<v-select
							v-model="localBankData.bankId"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:clearable="false"
							:options="bankOptions"
							:reduce="val => val.id"
							label="bank_name"
							input-id="bank"
						/>
						<b-form-invalid-feedback :state="getValidationState(validationContext)">
							{{ validationContext.errors[0] }}
						</b-form-invalid-feedback>
					</b-form-group>
				</validation-provider>

				<!-- Branch -->
				<validation-provider
					#default="validationContext"
					name="Branch"
				>
					<b-form-group
						:label="$t('Branch')"
						label-for="branch"
						hidden
					>
						<b-form-input
							id="branch"
							v-model="localBankData.branch"
							:state="getValidationState(validationContext)"
							trim
						/>

						<b-form-invalid-feedback>
							{{ validationContext.errors[0] }}
						</b-form-invalid-feedback>
					</b-form-group>
				</validation-provider>

			</b-form>
		</validation-observer>
	</b-modal>
</template>

<script>
import {
	BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BModal
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'

export default {
	components: {
		BSidebar,
		BForm,
		BFormGroup,
		BFormInput,
		BFormInvalidFeedback,
		BButton,
		vSelect,
		BModal,
		// Form Validation
		ValidationProvider,
		ValidationObserver,
	},
	directives: {
		Ripple,
	},
	props: {
		typeOptions: {
			type: Array,
			required: true,
		},
		bankOptions: {
			type: Array,
			required: true,
		},
		provinceLists: {
			type: Array,
			required: true,
		},
		levelLists: {
			type: [Array, null],
			required: true,
		},
		localTransferTypeLists: {
			type: [Array, null],
			required: true,
		},
	},
	data() {
		return {
			required,
			alphaNum,
			email,
		}
	},
	setup(props, { emit }) {
		const toast = useToast()
		const options = ref([])
		const blankLocalBankData = {
			bankId: '',
			bankAccountName: '',
			bankAccount: '',
		}

		const localBankData = ref(JSON.parse(JSON.stringify(blankLocalBankData)))
		const resetlocalBankData = () => {
			localBankData.value = JSON.parse(JSON.stringify(blankLocalBankData))
		}

		const onSubmit = () => {
			store.dispatch('payment-local-bank-account/addLocalBankAccount', localBankData.value)
				.then(response => {
					if (response.status == 200) {
						if (response.data.code == '00') {
							emit('refetch-data')
							toast({
								component: ToastificationContent,
								props: {
									title: 'Success',
									icon: 'CheckIcon',
									variant: 'success',
								},
							})
						} else {
							toast({
								component: ToastificationContent,
								props: {
									title: response.data.message,
									icon: 'AlertTriangleIcon',
									variant: 'danger',
								},
							})
						}
						emit('refetch-data')
					}
				})
				.catch((error) => {
					toast({
						component: ToastificationContent,
						props: {
							title: 'Error add local bank account',
							icon: 'AlertTriangleIcon',
							variant: 'danger',
						},
					})
				})
		}

		const resetModal = () => {
			localBankData.bankAccountName = '';
			localBankData.bankAccount = '';
			localBankData.bankId = '';
		}

		const {
			refFormObserver,
			getValidationState,
			resetForm,
		} = formValidation(resetlocalBankData)

		return {
			localBankData,
			onSubmit,
			options,

			refFormObserver,
			getValidationState,
			resetForm,
			resetModal,
			resetlocalBankData
		}
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
	.vs__dropdown-menu {
		max-height: 200px !important;
	}
}
</style>
