import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import i18n from '@/libs/i18n'
import { title, paginateArray, sortCompare } from '@core/utils/filter'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useLocalBankAccountsList() {
	// Use toast
	const toast = useToast()

	const refLocalBankAccountListTable = ref(null)

	// Table Handlers
	const tableColumns = [
		{ key: 'index', label: '#' },
		{ key: 'bank', label: i18n.t('Bank') },
		{ key: 'name', label: i18n.t('Name'), stickyColumn: true },
		{ key: 'account', label: i18n.t('Bank account') },
		{ key: 'vbank', label: 'V_Bank' },
		// { key: 'content', label: 'Nội dung chuyển khoản' },
		// { key: 'example_content', label: 'Ví dụ nội dung' },
		{key: 'status', label: i18n.t('Status')},
		{ key: 'createdBy', label: i18n.t('Created by') },
		{ key: 'createdAt', label: i18n.t('Created at') },
		{ key: 'updatedBy', label: i18n.t('Updated by') },
		{ key:'action', label: i18n.t('Actions')}
	]
	const perPage = ref(20)
	const totalLocalBankAccounts = ref(0)
	const currentPage = ref(1)
	const perPageOptions = [10, 25, 50, 100]
	const searchQuery = ref('')
	const sortBy = ref('id')
	const isSortDirDesc = ref(true)
	const bankOptions = ref(null)

	const refetchData = () => {
		refLocalBankAccountListTable.value.refresh()
	}

	watch([currentPage, perPage, searchQuery], () => {
		refetchData()
	})

	store.dispatch('local-setting-bank/fetchBanks', {
		status: null,
	})
		.then(response => {
			bankOptions.value = response
		})

	const fetchLocalBankAccounts = (ctx, callback) => {
		store
			.dispatch('payment-local-bank-account/fetchLocalBankAccounts', {
				q: searchQuery.value,
				perPage: perPage.value,
				page: currentPage.value,
				sortBy: sortBy.value,
				sortDesc: isSortDirDesc.value,
			})
			.then(response => {
				const { localBankAccounts, total, pageSize } = response
				callback(localBankAccounts)
				totalLocalBankAccounts.value = total
				// perPage.value = pageSize
			})
			.catch(() => {
				toast({
					component: ToastificationContent,
					props: {
						title: 'Error fetching local bank account list',
						icon: 'AlertTriangleIcon',
						variant: 'danger',
					},
				})
			})
	}

	const dataMeta = computed(() => {
		const localItemsCount = refLocalBankAccountListTable.value ? refLocalBankAccountListTable.value.localItems.length : 0
		return {
			from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
			to: perPage.value * (currentPage.value - 1) + localItemsCount,
			of: totalLocalBankAccounts.value,
		}
	})

	const resolveStatus = status => {
		if (status === 1) return { label: i18n.t('Active'), variant: 'success' }
		if (status === 2) return { label: i18n.t('Inactive'), variant: 'danger' }
		return { label: '-', variant: 'secondary' }
	}

	const statusOptions = [
		{ label: i18n.t('Active'), value: 1 },
		{ label: i18n.t('Inactive'), value: 2 },
	]

	return {
		fetchLocalBankAccounts,
		bankOptions,
		tableColumns,
		perPage,
		currentPage,
		totalLocalBankAccounts,
		dataMeta,
		perPageOptions,
		searchQuery,
		sortBy,
		isSortDirDesc,
		refLocalBankAccountListTable,

		resolveStatus,
		refetchData,

		statusOptions,

	}
}
